// Brand Identity Color Scheme
// $prussian-blue: #00274e;
// $curious-blue: #2b90e2;
// $jordy-blue: #27bdff;
// $mystic: #e6ecf1;
// $abbey: #464749;
// $star-dust: #a2a2a0;
// $westar: #e1dfdd;
// $white: #ffffff;
// $tia-maria: #d2611c;
// $orange-peel: #ff9a00;

// Epicor Branding
$teal: #025064;
$aqua: #90D2B5;
$white: #ffffff;
$black: #000000;
$red: #ff2102;
$light-gray: #eef2f5;

// Theme Colors
$saffron-mango: #f9c655;
$pear: #b5e530;
$turquoise: #30e1e5;
$curious-blue: #2b90e2;
$cornflower-blue: #7d7dff;

// Darker Theme Colors
$medium-carmine: #ad3b3b; // Dark Red
$tia-maria: #c24a11; // Dark Orange
$old-gold: #cea144; // Dark Yellow
$limeade: #7ab200; // Dark Teal
$forest-green: #23af41; // Dark Green
$bondi-blue: #00a6b4; // Dark Cyan
$tory-blue: #0c65b1; // Blue
$butterfly-bush: #5151a5; // Dark Purple

// Grayscale
$gray-100: $light-gray;
$gray-200: #e1dfdd;
$gray-300: #d5d6d3;
$gray-400: #a2a2a0;
$gray-500: #70747a;
$gray-600: #636466;
$gray-700: #464749;
$gray-800: #323535;
$gray-900: #2d2e2e;
$gray-1000: #1f2121;
$none: #00000000;

$grays: (
	'gray-100': $gray-100,
	'gray-200': $gray-200,
	'gray-300': $gray-300,
	'gray-400': $gray-400,
	'gray-500': $gray-500,
	'gray-600': $gray-600,
	'gray-700': $gray-700,
	'gray-800': $gray-800,
	'gray-900': $gray-900,
	'gray-1000': $gray-1000,
	'none': $none,
);

$colors: (
	'red': $red,
	'orange': #ff9a00,
	'yellow': $saffron-mango,
	'green': $aqua,
	'teal': $pear,
	'cyan': $turquoise,
	'blue': $curious-blue,
	'purple': $cornflower-blue,
);

$colors-dark: (
	'red-dark': $medium-carmine,
	'orange-dark': $tia-maria,
	'yellow-dark': $old-gold,
	'teal-dark': $limeade,
	'green-dark': $forest-green,
	'cyan-dark': $bondi-blue,
	'blue-dark': $tory-blue,
	'purple-dark': $butterfly-bush,
	'primary-dark': $tory-blue,
);

$theme-colors: (
	'primary': $teal,
	'primary-light': $aqua,
	'secondary': $red,
	'success': map-get($colors, 'green'),
	'info': $tory-blue,
	'warning': map-get($colors, 'orange'),
	'danger': $red,
	'gray-light': $gray-300,
	'gray': #a2a2a0,
	'gray-dark': #464749,
	'white': $white,
	'none': $none,
);

$theme-colors: map-merge($theme-colors, $colors);
$theme-colors: map-merge($theme-colors, $colors-dark);
$theme-colors: map-merge($theme-colors, $grays);
