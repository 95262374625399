// Prepend bootstrap partials
@import './variables';
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/utilities';

// Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// Components
@import './components/_button';
@import './components/_carousel';
@import './components/_chips';
@import './components/_navbar';

iframe {
	@extend .w-100, .h-100;
}

a {
	color: map-get($theme-colors, 'primary');
	text-decoration: none;
	font-weight: 600;
	&:hover {
		color: map-get($theme-colors, 'primary-light');
	}
}

strong {
	font-weight: 700;
}

code {
	@extend .text-orange-dark;
}

pre code {
	@extend .bg-gray-800, .text-white;
	display: block;
	padding: 20px;
	border-radius: 0.5rem;
}

// Titillium Web
.ff-titillium-web {
	font-family: 'Titillium Web', sans-serif !important;
}

// Skeleton loading
.loading {
	background: linear-gradient(
		270deg,
		map-get($theme-colors, 'none'),
		map-get($theme-colors, 'gray-300')
	);
	background-size: 400% 400%;
	animation: loading 2s ease infinite;
}

.max-line {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@keyframes loading {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

// Video Tutorial Page
.video-tile {
	&:focus {
		@extend .bg-gray-100;
	}
}

.table-borderless td,
.table-borderless th {
	border: 0;
}

.table-row {
	&:hover {
		@extend .bg-gray-100;
	}
}

// Links
.link-white:hover,
.link-white:focus {
	color: #c4c4c4;
}
.link-gray-500:hover,
.link-gray-500:focus {
	color: map-get($theme-colors, 'black');
}

// Buttons
.btn:focus {
	box-shadow: none;
}

// All Pages
.masthead {
	min-height: 40rem;
}

.responsive-clip-path-static {
	@extend .overflow-hidden, .d-flex;
	clip-path: inset(0 0 0 0);
	img {
		@extend .w-auto, .h-100, .position-relative;
		min-width: 100%;
		-webkit-align-self: center;
		align-self: center;
	}
}

.responsive-clip-path {
	@extend .overflow-hidden;
	clip-path: inset(0 0 0 0);
	img {
		@extend .w-auto, .h-100, .position-relative;
		object-fit: cover;
		min-width: 100%;
		transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		left: 50%;
		top: 50%;
	}
}

.fw-semibold {
	font-weight: 500 !important;
}

strong {
	font-weight: 700 !important;
}

// ReactPlayer
.player-wrapper {
	position: relative;
	height: 0;
	padding-top: 56.25%;
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

//- Mobile (screen size < lg)
@include media-breakpoint-down(lg) {
	.masthead {
		min-height: unset;
	}
	.lead {
		font-size: $font-size-base;
	}
}
