// Profile img Nav Link
.nav-link {
	transition-duration: 0s;
	img {
		transition: all 0.2s;
	}
	&:hover {
		img {
			transform: scale(1.1);
			box-shadow: $box-shadow;
		}
	}
}

.nav-link.active {
	color: $nav-link-active-color;
	border-color: $nav-pills-link-active-bg !important;
	img {
		outline: map-get($theme-colors, 'primary') solid 4px;
	}
}

.nav-link.disabled {
	opacity: 0.5;
}

// Dropdown
.dropdown-menu {
	@extend .p-0, .mt-0;
	min-width: 100%;
	box-shadow: $box-shadow;
}

#header .dropdown-menu {
	width: max-content;
}

.navbar-toggler {
	line-height: unset;
	&:focus {
		box-shadow: none;
	}
}

.dropdown-toggle:after {
	display: none;
}

// Navbar dark
.navbar-dark {
	.nav-link,
	.btn-link {
		@extend .text-gray-400;
		&:hover {
			@extend .text-primary-light;
		}
	}
}

//- Desktop (> lg) Navbar
@include media-breakpoint-up(lg) {
	#header {
		.dropdown {
			&:hover {
				background-color: map-get($theme-colors, 'white');
				.dropdown-menu {
					display: block;
					margin-top: 0;
				}
				.dropdown-toggle {
					.fa-angle-down {
						transition: 0.2s all;
						transform: rotate(-180deg);
						opacity: 0.5;
					}
				}
			}
		}
		.dropdown-toggle {
			.fa-angle-down {
				transition: 0.2s all;
			}
		}
	}

	// Remove Borders from Carousels
	.nav-pills {
		border: none !important;
	}
}

//- Mobile (< lg) Navbar
@include media-breakpoint-down(lg) {
	#header {
		//- Sizing and animation
		.navbar-collapse {
			height: 100%;
			background-color: map-get($theme-colors, 'gray-800');
			color: map-get($theme-colors, 'white');
			position: fixed;
			top: 4.5rem;
			right: 0;
		}

		.navbar-collapse.collapsing {
			right: -100%;
			transition: height 0s ease;
		}

		.navbar-collapse.show {
			right: 0;
			transition: right 200ms ease-in-out;
		}

		.navbar-toggler.collapsed ~ .navbar-collapse {
			transition: right 300ms ease-in-out;
		}

		//- Mobile-specific re-styles
		.dropdown-menu {
			width: 100% !important;
			background-color: map-get($theme-colors, 'gray-700');
			color: $navbar-dark-color !important;
			&.show {
				position: inherit;
			}
		}

		.nav-link.dropdown-toggle {
			display: flex !important;
			align-items: center !important;
			.fa-caret-down {
				transition: none;
				margin-left: auto !important;
				margin-right: 0.15rem !important;
			}
		}

		.nav-link:not(.dropdown-toggle),
		.navbar a.cta_button {
			box-sizing: border-box !important;
			display: flex !important;
			align-items: center !important;
			&:after {
				margin-left: auto !important;
				margin-right: 0.15rem !important;
			}
		}

		// Change colors for hover nav items
		.nav-item,
		.nav-link {
			&:hover,
			&[aria-expanded='true'] {
				background-color: $gray-700 !important;
			}
		}

		// add a bottom border to all top-level items
		.dropdown,
		a.nav-link {
			border-bottom: 0.0125rem solid map-get($theme-colors, 'gray-600');
		}

		.nav-link,
		nav a.cta_button,
		nav .dropdown-item {
			color: $navbar-dark-color !important;
			&:hover,
			&:focus {
				color: $navbar-dark-hover-color !important;
			}
		}

		.border-gray-200 {
			border-color: map-get($theme-colors, 'gray-600') !important;
		}

		small {
			color: map-get($theme-colors, 'gray-400') !important;
		}

		// Style Buttons different for mobile
		button {
			font-size: 1rem;
		}

		.btn-outline-gray-800 {
			color: white;
			&:hover {
				background-color: map-get($theme-colors, 'gray-700');
			}
		}
	}
}
