@import './colors';

$border-width: 0.5px;
$border-color: map-get($theme-colors, 'gray-800');
$body-bg: map-get($theme-colors, 'gray-800');

// Container
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1400px,
);

// Base Typography
$body-color: $gray-700;
$font-family-sans-serif: 'Titillium Web', sans-serif !important;
$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600; // strong tag set in _base
$font-weight-bolder: 1000;
$font-size-base: 1rem;
$font-size-lg: 1.375rem;

// Secondary Typography
$input-border-width: 0.5px;
$link-color: map-get($theme-colors, 'brand');

// Headings
$headings-font-family: 'Hind', sans-serif !important;
$headings-margin-bottom: 1rem;
$headings-font-weight: 700;
$h1-font-size: $font-size-base * 2.25;
$h4-font-size: $font-size-base * 1.375;
$headings-color: $gray-800;
$headings-line-height: 90%;

// Display
$display-line-height: 90%;
$display-font-weight: 700;
$display-font-sizes: (
	1: 4.5rem,
	2: 4rem,
	3: 3.5rem,
	4: 3rem,
	5: 2.5rem,
	6: 2.25rem,
);

// Native Columns
$col-gap: 2rem;

// Lead
$lead-font-size: $font-size-lg;
$lead-font-weight: 300;

// Small
$small-font-size: 90%;

// Inputs
$input-placeholder-color: #a2a2a0;
$input-padding-y: 0.5rem;
$input-padding-x: 1rem;
$input-padding-y-lg: 1rem;
$input-padding-x-lg: 2rem;
$input-font-size: 0.875rem;

// Buttons
$btn-padding-x: 1.5rem;
$btn-padding-y: 1.25rem;
$btn-border-width: 0.125rem;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-font-weight: $font-weight-bold;
$btn-font-family: 'Hind', sans-serif !important;
$btn-t-duration: 0.15s;

// Dropdowns
$dropdown-min-width: 16rem;
$dropdown-padding-y: 2rem;
$dropdown-border-width: 0;
$dropdown-link-color: map-get($theme-colors, 'gray-dark');
$dropdown-link-hover-color: map-get($theme-colors, 'primary');
$dropdown-link-hover-bg: $none;
$dropdown-item-padding-y: 0.5rem;
$dropdown-link-active-color: map-get($theme-colors, 'primary-light');;
$dropdown-link-active-bg: $none;
$dropdown-border-radius: 0;

// Links
$link-color: map-get($theme-colors, 'primary');
$link-hover-color: map-get($theme-colors, 'primary-dark');

// Navbar
$navbar-dark-color: $white;
$navbar-light-color: $headings-color;
$navbar-light-active-color: map-get($colors, 'primary-light');
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;
$navbar-padding-y: 0;

// Nav
// $nav-tabs-border-radius: 0;
$nav-link-color: map-get($theme-colors, 'gray-800');
$nav-link-hover-color: map-get($theme-colors, 'primary-light');
$nav-link-active-color: map-get($theme-colors, 'primary');
$nav-link-font-weight: 600;
$nav-link-padding-y: 1.5rem;
$nav-link-padding-x: 1.5rem;
$nav-pills-link-active-bg: map-get($theme-colors, 'gray-800');
$nav-tabs-link-active-border-color: map-get($theme-colors, 'gray-800');

// Badges
$badge-font-size: 0.875rem;
$badge-border-radius: 800px;
$badge-color: $body-color;
$badge-padding-y: 0.5rem;
$badge-padding-x: 0.75rem;

// Cards
$card-border-radius: 0;
$card-border-width: 0.1rem;

// Spacing
$spacer: 1rem;
$spacers: (
	0: 0,
	1: (
		$spacer * 0.25,
	),
	2: (
		$spacer * 0.5,
	),
	3: $spacer,
	4: (
		$spacer * 1.5,
	),
	5: (
		$spacer * 3,
	),
	6: (
		$spacer * 5,
	),
);

$enable-negative-margins: true;

// Custom Variables
$breakpoint-xxl: 2175px;

// Transition
$t-scale-value: scale(1.05);

//- Blend Mode
$blend-modes: 'multiply', 'exclusion', 'hue', 'screen', 'lighten';

// Box Shadow
$box-shadow: 0.25rem 0.25rem 8px rgba($black, 0.1);
