// Pills
.nav-pills .nav-link {
	@extend .rounded-pill;
	cursor: pointer;

	&:not(.active) {
		@extend .border, .border-gray-400, .bg-gray-100;
		transition: background-color 0.4s;
		&:hover {
			@extend .bg-gray-300;
			color: inherit;
		}
	}
}

.nav-pills {
	.nav-link,
	.btn:not(.rounded-circle) {
		@extend .py-1;
		padding-left: 0.75rem !important;
		padding-right: 0.75rem !important;
		font-size: 0.875rem;
		max-width: 10rem;
		// Center button text.
		span {
			@extend .position-relative;
			top: 1px;
		}
	}
	// Match nav-pills buttons to nav-link:not(.active)
	.btn:not(.rounded-circle) {
		@extend .rounded-pill, .border-gray-400, .bg-gray-100;
		border-width: 0.5px;
		&:hover {
			@extend .bg-gray-300;
			color: inherit;
		}
	}
}
