// Carousel
.carousel-arrow {
	@extend .position-absolute, .h-100, .d-flex, .align-items-center;
	pointer-events: none;
	height: 100%;
	width: 4rem;
	.btn {
		pointer-events: all;
		width: 2rem;
		height: 2rem;
		&:hover {
			@extend .bg-primary, .text-white;
		}
		svg {
			@extend .position-relative;
			top: 1px;
		}
	}
}

.carousel-left {
	left: 0;
	background: linear-gradient(
		-90deg,
		rgba(68, 38, 38, 0) 0%,
		rgba(255, 255, 255, 1) 50%
	);
	svg {
		right: 1px;
	}
}

.carousel-right {
	right: 0;
	background-image: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 50%
	);
	background-repeat: no-repeat;
	.btn {
		@extend .ms-auto;
	}
	svg {
		left: 1px;
	}
}

// Carousel
.slick-track {
	@extend .ms-0;
}

.slick-list {
	margin: 0 -0.25rem;
}

.slick-slide {
	padding: 0 0.25rem;
}

.slick-prev,
.slick-next {
	@extend .w-auto;
	font-size: 1rem !important;
}

.slick-prev:before,
.slick-next:before {
	content: '' !important;
}

.slick-disabled {
	opacity: 0.25;
}

@include media-breakpoint-down(lg) {
	.carousel-arrow {
		width: 2rem;
	}
}
